import { Button, ButtonProps, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import type { PolymorphicComponentProps } from "@mantine/utils";
import _ from "lodash";
import { PopupModal } from "react-calendly";

export const HEN_CALENDLY_SCHEDULE_DEMO_URL = "https://calendly.com/comprehensiveio/demo";

function getDoc() {
  if (typeof document === "undefined") return null;
  return document;
}

export function HenCalendlySchedulerButton({
  onClick = _.noop,
  ...buttonProps
}: PolymorphicComponentProps<"button", ButtonProps>) {
  const [isOpened, handlers] = useDisclosure(false);
  const theme = useMantineTheme();

  return (
    <>
      <Button
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          onClick(event);
          handlers.open();
        }}
        {...buttonProps}
      />
      <PopupModal
        url={HEN_CALENDLY_SCHEDULE_DEMO_URL}
        open={isOpened}
        onModalClose={handlers.close}
        pageSettings={{
          // Colors won't work unless we're using a pro plan. :shrug:
          primaryColor: theme.colors.teal[6],
        }}
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={getDoc()?.getElementById("root") as HTMLElement}
      />
    </>
  );
}
