import { useCallback, useMemo, useState } from "react";

import {
  Badge,
  Modal,
  ModalProps,
  Stack,
  Group,
  Button,
  SimpleGrid,
  Divider,
  Box,
} from "@mantine/core";
import { Lightning } from "@phosphor-icons/react";
import { MantinePhosphor } from "app/ui/components/mantine-phosphor";
import { useSafeDelayedValueOnModalClose } from "app/ui/hooks/useDelayedValue";
import { mantineComponentFactory } from "app/ui/lib/mantine-component-factory";
import Image, { StaticImageData } from "next/image";

import { HenCalendlySchedulerButton } from "components/HenCalendlySchedulerButton";
import { constrainImageDimensions } from "ui/utils/constrainImageDimensions";

import ReviewsUpsellContentGraphic from "./assets/graphic-comp-reviews.png";
import PayRangesUpsellContentGraphic from "./assets/graphic-pay-ranges.png";
import TotalRewardsUpsellContentGraphic from "./assets/graphic-total-rewards.png";

type PaidFeatureName =
  | "reviews"
  | "pay-ranges"
  | "total-rewards"
  | "benchmarking-comptryx"
  | "benchmarking-mercer";

export type PaidFeatureModalState =
  | {
      feature: PaidFeatureName;
      opened: true;
    }
  | {
      feature: null;
      opened: false;
    };

export function usePaidFeatureModalDisclosure(): [
  PaidFeatureModalState,
  {
    open: (feature: PaidFeatureName) => void;
    close: () => void;
  },
] {
  const [state, setState] = useState<PaidFeatureModalState>({
    opened: false,
    feature: null,
  });

  const open = useCallback(
    (feature: PaidFeatureName) => {
      setState({ opened: true, feature });
    },
    [setState],
  );

  const close = useCallback(() => {
    setState({ opened: false, feature: null });
  }, [setState]);

  const handlers = useMemo(() => ({ open, close }), [open, close]);

  return [state, handlers];
}

const PfmTitle = mantineComponentFactory.Title("PaidFeatureModalContent.Title", {
  order: 3,
});

const PfmDesc = mantineComponentFactory.Text("PaidFeatureModalContent.Description", {
  fz: "sm",
  c: "gray.5",
});

const PfmStack = mantineComponentFactory.Stack("PaidFeatureModalContent.Stack", {
  align: "center",
  justify: "center",
  ta: "center",
});

function PfmBadge() {
  return (
    <Badge color="green" sx={{ textTransform: "none" }} size="lg">
      <Group gap={4}>
        <MantinePhosphor icon={Lightning} color="yellow.5" weight="fill" />
        Paid Feature
      </Group>
    </Badge>
  );
}

function PfmGraphic({ staticImageData, alt }: { staticImageData: StaticImageData; alt: string }) {
  return (
    <Box
      mt={32}
      bg="gray.2"
      sx={(theme) => ({
        border: `24px solid ${theme.colors.gray[2]}`,
        borderBottomWidth: 1,
        borderRadius: 12,
      })}
    >
      <Box
        sx={{ borderTopLeftRadius: 12, borderTopRightRadius: 12, overflow: "hidden" }}
        w="100%"
        pos="relative"
      >
        <Image
          src={staticImageData.src}
          {...constrainImageDimensions({
            ...staticImageData,
            newWidth: 270,
          })}
          alt={alt}
        />
      </Box>
    </Box>
  );
}

function PaidFeatureModalReviewsContent() {
  return (
    <>
      <PfmStack>
        <PfmBadge />
        <PfmTitle>Comp Reviews</PfmTitle>
        <PfmDesc>
          Save time and eliminate mistakes on your next cycle — without a single spreadsheet.
        </PfmDesc>
      </PfmStack>
      <PfmGraphic staticImageData={ReviewsUpsellContentGraphic} alt="Comp Reviews" />
    </>
  );
}

function PaidFeatureModalPayRangesContent() {
  return (
    <>
      <PfmStack>
        <PfmBadge />
        <PfmTitle>Pay Ranges</PfmTitle>
        <PfmDesc>
          Store, edit, and selectively share your pay bands from a single source-of-truth.
        </PfmDesc>
      </PfmStack>
      <PfmGraphic staticImageData={PayRangesUpsellContentGraphic} alt="Pay Ranges" />
    </>
  );
}

function PaidFeatureModalTotalRewardsContent() {
  return (
    <>
      <PfmStack>
        <PfmBadge />
        <PfmTitle>Total Rewards</PfmTitle>
        <PfmDesc>
          Educate employees about their full compensation, including the value of equity and
          benefits.
        </PfmDesc>
      </PfmStack>
      <PfmGraphic staticImageData={TotalRewardsUpsellContentGraphic} alt="Total Rewards" />
    </>
  );
}

function PaidFeatureModalBenchmarkingContent() {
  return (
    <>
      <PfmStack>
        <PfmBadge />
        <PfmTitle>Premium Benchmarking</PfmTitle>
        <PfmDesc>Access global, multi-industry compensation data from 9M employees.</PfmDesc>
      </PfmStack>
      <PfmGraphic staticImageData={ReviewsUpsellContentGraphic} alt="Premium Benchmarking" />
    </>
  );
}

function PaidFeatureModalContent({ feature }: { feature: PaidFeatureModalState["feature"] }) {
  if (feature === "reviews") {
    return <PaidFeatureModalReviewsContent />;
  }
  if (feature === "pay-ranges") {
    return <PaidFeatureModalPayRangesContent />;
  }
  if (feature === "total-rewards") {
    return <PaidFeatureModalTotalRewardsContent />;
  }
  if (feature === "benchmarking-comptryx") {
    return <PaidFeatureModalBenchmarkingContent />;
  }
  if (feature === "benchmarking-mercer") {
    return <PaidFeatureModalBenchmarkingContent />;
  }
  return null;
}

function PaidFeatureModalCtas() {
  return (
    <Stack gap={24}>
      <Divider color="gray.2" mx={-24} />
      <SimpleGrid cols={2} spacing={12}>
        <Button
          size="md"
          variant="outline"
          component="a"
          target="_blank"
          href="https://comprehensive.io"
        >
          Learn More
        </Button>
        <HenCalendlySchedulerButton size="md">Schedule a Demo</HenCalendlySchedulerButton>
      </SimpleGrid>
    </Stack>
  );
}

export function PaidFeatureModal({
  feature: unsafeFeature,
  ...modalProps
}: Omit<ModalProps, "opened"> & PaidFeatureModalState) {
  const feature = useSafeDelayedValueOnModalClose(unsafeFeature);
  return (
    <Modal
      {...modalProps}
      size="sm"
      styles={{
        header: {
          // Hide some stuff
          borderBottomWidth: 0,
          marginBottom: -36,
        },
      }}
    >
      <Stack align="center" justify="center" gap={24}>
        <Box mb={32} px={24}>
          <PaidFeatureModalContent feature={feature} />
        </Box>
      </Stack>
      <PaidFeatureModalCtas />
    </Modal>
  );
}
