import { useCallback } from "react";

import { useMantineTheme } from "@mantine/core";
import _ from "lodash";

export * from "./eyebrow";
export * from "./hover-popover";
export * from "./tooltip-target-text";
export * from "./checkbox-list";

export function packSx<T>(sx: T | T[] | undefined): T[] {
  if (!sx) return [];
  return Array.isArray(sx) ? sx : [sx];
}

export function useBoundPackSx() {
  const theme = useMantineTheme();
  const unpackSx = useCallback(
    // @ts-expect-error
    (...args: unknown[]) => {
      if (args.length > 1) return unpackSx(args);
      if (args.length === 0) return {};
      const sx = args[0];
      if (_.isArray(sx)) return sx.map((entry) => unpackSx(entry));
      if (_.isFunction(sx)) return sx(theme);
      return sx;
    },
    [theme],
  );
  return unpackSx;
}
