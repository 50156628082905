import { AnyObject } from "@hen/stdlib/types";
import { Badge, MantineSize } from "@mantine/core";
import _ from "lodash";

const attrsBySize: Record<MantineSize, AnyObject> = {
  xs: {
    root: {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  sm: {
    root: {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  md: {
    root: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  lg: {
    root: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  xl: {
    root: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
};

export const badgeComponentSettings = Badge.extend({
  defaultProps: {
    color: "teal.6",
    variant: "light",
  },
  styles: (theme, props) => {
    const { size = "md" } = props;
    const sizeAttrs = attrsBySize[size as keyof typeof attrsBySize];
    return {
      root: {
        "text-transform": "none",
        ..._.merge({}, sizeAttrs.root),
        "&[data-variant='dot']": {
          borderColor: theme.colors.gray[3],
        },
      },
      inner: {
        fontWeight: theme.other.fontWeights.medium,
      },
    };
  },
});
