import { Button, ButtonProps, DefaultMantineColor, MantineSize, MantineTheme } from "@mantine/core";
import { merge } from "lodash";

const sizeAttrs = {
  sm: {
    root: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 8,
      paddingBottom: 8,
    },
    label: {
      fontSize: 14,
      lineHeight: "14px",
    },
  },
  md: {
    root: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
      paddingBottom: 12,
    },
    label: {
      fontSize: 14,
      lineHeight: "14px",
    },
  },
  lg: {
    root: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
      paddingBottom: 16,
    },
    label: {
      fontSize: 16,
      lineHeight: "16px",
    },
  },
};

const getSizeAttrs = ({ size: rawSizeOrig }: Pick<ButtonProps, "size">) => {
  let size: Extract<MantineSize, keyof typeof sizeAttrs>;
  // let rawSizeAfter = if starts with "compact-" remove it
  let rawSize = rawSizeOrig?.startsWith("compact-") ? rawSizeOrig.slice(8) : rawSizeOrig;
  if (rawSize === "xs") {
    // Note that xs uses the foundation of sm but with additional styles
    // that Mantine will layer in.
    size = "sm";
  } else if (rawSize === "xl") {
    console.warn('Unsupported button size detected. Transforming "xl" to "lg".');
    size = "lg";
  } else {
    size = rawSize as keyof typeof sizeAttrs;
  }

  return sizeAttrs[size];
};

const getColorAttrs = (
  { color, variant }: Required<Pick<ButtonProps, "color" | "variant">>,
  theme: MantineTheme,
) => {
  if (variant === "white") {
    return {};
  }

  const rawThemeColor = color ? theme.colors[color] : undefined;
  if (!rawThemeColor) {
    console.warn(`Could not find theme config for color: ${color}`);
  }
  const themeColor = rawThemeColor || theme.primaryColor;

  if (variant === "filled") {
    return {
      root: {
        borderColor: "transparent",
        backgroundColor: themeColor[7],
        boxShadow: theme.shadows.sm,
        "&:hover": {
          backgroundColor: themeColor[8],
        },
        '&:not([data-loading="true"])': {
          "&:disabled, &[data-disabled]": {
            backgroundColor: theme.colors.gray[2],
            borderColor: theme.colors.gray[3],
            boxShadow: "none",
            "&:hover": {
              backgroundColor: theme.colors.gray[2],
            },
          },
        },
      },
      label: {
        color: theme.colors.gray[0], //white
        ":disabled &, [data-disabled] &": {
          color: theme.colors.gray[5],
        },
      },
    };
  }

  if (variant === "outline") {
    return {
      root: {
        // TODO: This exception is real terrible
        // @ts-expect-error TS18048
        borderColor: rawThemeColor[color === "gray" ? 3 : 5],
        backgroundColor: theme.colors.gray[0], // white, but maybe should be transparent?
        boxShadow: theme.shadows.sm,
        "&:hover": {
          // @ts-expect-error TS18048
          borderColor: rawThemeColor[color === "gray" ? 4 : 6],
          backgroundColor: theme.colors.gray[1],
        },
      },
      label: {
        color: themeColor[8],
        ":hover &": {
          color: themeColor[8],
        },
        ":disabled &, [data-disabled] &": {
          color: themeColor[5],
        },
      },
    };
  }

  return { root: {} };
};

export const buttonComponentSettings = Button.extend({
  styles: (theme, props) => {
    const { color: rawColor } = props;
    const { size = "md", variant = "filled" } = props;

    /**
     * In lieu of typical "primary" and "secondary" variants, we use
     * "filled" and "outline" with a different default color for ease
     * of use.
     */
    const color: DefaultMantineColor = rawColor || (variant === "outline" ? "gray" : "teal");

    const sizeAttrs = getSizeAttrs({
      // We're effectively ignoring this for now - my hope is that much of this
      // can go away but in the meantime it's not bothering anything.
      size: size as MantineSize,
    });
    const colorAttrs = getColorAttrs({ color, variant }, theme);

    return {
      root: {
        ...merge({}, sizeAttrs.root, colorAttrs.root),

        transition: "0.1s background-color ease, 0.1s border-color ease, 0.1s color ease",
        borderWidth: "1px",
        borderStyle: "solid",

        "&:active": {
          // Mantine suppplies a translateY by default that we wish to
          // override.
          transform: "none",
          backgroundColor: colorAttrs.root?.backgroundColor,
        },
      },
      label: {
        ...merge({}, sizeAttrs.label, colorAttrs.label),

        // Allows text to flow outside the label - particularly
        // important for characters that dip below the baseline.
        overflow: "visible",

        fontFamily: theme.other.fontStacks.inter,
        fontWeight: theme.other.fontWeights.medium,
      },
      section: {
        [`&[data-position="left"]`]: {
          marginRight: theme.spacing.md,
        },
        [`&[data-position="right"]`]: {
          marginLeft: theme.spacing.md,
        },
      },
    };
  },
});
