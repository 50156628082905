import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/spotlight/styles.css";
import "@mantine/nprogress/styles.css";

import { ReactElement, ReactNode, useMemo } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { MantineProvider } from "@mantine/core";
import { MantineEmotionProvider, emotionTransform } from "@mantine/emotion";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { mantineLightTheme } from "app/ui/theme";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";

import { AuthProvider } from "components/AuthProvider";
import ErrorBoundary from "components/ErrorBoundary";
import { getDefaultLayout } from "components/layouts/default";
import { GaSnippet } from "components/lib/ga";
import { HeapSnippet } from "components/lib/heap";
import { emotionCacheBenchmarking } from "emotion/cache";
import logger from "utils/logger";
import { getCanonicalUrl, getPageTitle } from "utils/utils";

import { en } from "../constants/locale/en";

// import "./prebuilt.css";

import "app/lib/frontend/cm/index.scss";
// import "components/logic-editor/logic-editor.scss";
import "components/google-one-tap/google-one-tap.scss";
import "components/slope-note-message/slope-note-message.scss";
import "components/slope-note-message-list/slope-note-message-list.scss";
import "components/input/input.scss";
import "components/text-editor/text-editor.scss";
import "components/text-editor/variable-list.scss";
import "components/nominal-percent-input/nominal-percent-input.scss";
import "components/dialog/dialog.scss";
import "components/ag-grid/ag-grid-cell-bonus-target-input/ag-grid-cell-bonus-target-adjustment-input.scss";
import "components/ag-grid/ag-grid-cell-icon-tooltip/ag-grid-cell-icon-tooltip.scss";
import "components/ag-grid/ag-grid-cell-box-plot/ag-grid-cell-box-plot.scss";
import "components/ag-grid/ag-grid-cell-currency-percent-increase/ag-grid-cell-currency-percent-increase.scss";
import "components/ag-grid/ag-grid-header-tooltip/ag-grid-header-tooltip.scss";
import "components/ag-grid/ag-grid-cell-percent-increase/ag-grid-cell-percent-increase.scss";
import "components/ag-grid/ag-grid-equity-grants-input/ag-grid-equity-grants-input.scss";
import "components/table/table.scss";
import "pages/settings/settings-data/settings-history/components/import-list.scss";
import "pages/reviews/[id]/setup/views/_components/setup-views.scss";
import "pages/reviews/[id]/setup/suggestions/_components/interval-editor.scss";
import "pages/reviews/[id]/analytics/pay-equity/_pay-equity.scss";
import "pages/award-letter/award-letter-template.scss";
import "pages/employee-award-letter/employee-award-letter.scss";
import "pages/reviews/[id]/award-letters/admin/_components/PreviewAwardLetterModal/award-letter-preview-dialog.scss";
import "pages/reviews/[id]/_components/LegacyReviewDetail/action-box/action-box.scss";
import "pages/review-overview/components/cell-renderers/ag-grid-cell-simple-suggestion/AgGridCellSimpleSuggestion.scss";
import "pages/company/employees/invite/_components/invite.scss";
import "pages/review-list/review-list-parent.scss";

const SOCIAL_IMAGE_URL =
  "https://uploads-ssl.webflow.com/64c92a0b7a48fb18553ccdc8/64fa2a0ad8c9533d132806a2_comprehensive-social-share.png";

if (typeof window !== "undefined" && !(window as any).DD_RUM) {
  datadogRum.init({
    applicationId: "019943a7-889b-475a-9d65-b235fc793ab0",
    clientToken: "pub49bda995e83e23274ba4c2e6dab9f183",
    site: "datadoghq.com",
    service: "benchmarking-frontend",
    env: process.env.NEXT_PUBLIC_RUNTIME_ENV ?? "unknown",
    version: process.env.NEXT_PUBLIC_RELEASE_VERSION,
    allowedTracingUrls: [
      /https:\/\/.*\.comprehensive\.io/,
      /https:\/\/.*cm-app-pr.*/,
      (url) => url.includes("comprehensive.io") || url.includes("cm-app-pr"),
    ],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    beforeSend: (event) => {
      const isFromLocalhost = event.view.url.indexOf("localhost") !== -1;
      if (isFromLocalhost) {
        return false;
      }

      const isInternalToolsRoute = event.view.url.includes("internal-tools/cap");
      if (isInternalToolsRoute) {
        return false;
      }
      return true;
    },
  });

  (window as any).DD_RUM = datadogRum;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function TrackerApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? getDefaultLayout;
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            // staleTime: twentyFourHoursInMs,
          },
        },
      }),
    [],
  );
  const router = useRouter();

  const pageTitle = useMemo(() => {
    let title;
    try {
      title = getPageTitle(router, pageProps.results, pageProps.stats);
    } catch (e) {
      // @ts-expect-error TS2345
      logger.error("Error creating page title", e);
      title = en.translations.seo_title;
    }
    return title;
  }, [router, pageProps.results, pageProps.stats]);

  const canonicalUrl = useMemo(() => {
    let canonical = process.env.NEXT_PUBLIC_APP_URL ?? "https://comprehensive.io";
    try {
      canonical = getCanonicalUrl(router);
    } catch (e) {
      // @ts-expect-error TS2345
      logger.error("Error creating canonical URL", e);
    }
    return canonical;
  }, [router]);

  const pageDescription = useMemo(() => {
    let description;
    try {
      // description = getPageDescription(router, pageProps.results, pageProps.stats);
      description = en.translations.seo_description;
    } catch (e) {
      logger.error("Error creating page description");
      description = en.translations.seo_description;
    }
    return description;
  }, [router, pageProps.results, pageProps.stats]);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta charSet="utf-8" />
        <meta title={pageTitle} />
        <meta name="description" content={pageDescription} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={SOCIAL_IMAGE_URL} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
        />

        <meta name="facebook-domain-verification" content="bsz92905is1ems6vaz38rjojxxd2gu" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={SOCIAL_IMAGE_URL} />

        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={canonicalUrl} />

        <HeapSnippet heapAppId={process.env.NEXT_PUBLIC_HEAP_APP_ID} />
        <GaSnippet />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <MantineEmotionProvider cache={emotionCacheBenchmarking}>
            <MantineProvider theme={mantineLightTheme} stylesTransform={emotionTransform}>
              <ErrorBoundary datadogRum={datadogRum}>
                <Notifications />
                {getLayout(<Component {...pageProps} />)}
              </ErrorBoundary>
            </MantineProvider>
          </MantineEmotionProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  );
}
