import React from "react";

import { AppShell } from "@mantine/core";
import { primaryTopNavParentGroupHeight } from "app/ui/components/primary-top-nav";
import _ from "lodash";

import { useAuth } from "components/AuthProvider";
import { TrackerFooter } from "components/TrackerFooter";
import { TrackerHeader } from "components/TrackerHeader";
import { useTrackHeapPageLoad } from "hooks/heap";

type HenAppShellProps = {
  children: JSX.Element | JSX.Element[];
  contentBackground?: string;
};

export function TrackerAppShell({ children, ...props }: HenAppShellProps): JSX.Element {
  const { user } = useAuth();

  useTrackHeapPageLoad();

  return (
    <AppShell
      sx={{
        background: props.contentBackground ?? "linear-gradient(180deg, #FFFFFF 24%, #FAFAFC 32%)",
        overflow: "hidden",
      }}
      data-heap-auth={!_.isNil(user)}
      styles={{
        main: {
          paddingRight: 0,
          paddingLeft: 0,
          paddingBottom: 0,

          // In theory this value is managed by mantine via the height
          // prop on the Header, but for some reason mantine is adding
          // a 6px offset to that value via calc. Unclear why. Setting
          // it here resolves the issue.
          paddingTop: primaryTopNavParentGroupHeight,
        },
      }}
    >
      <TrackerHeader />
      <AppShell.Main>
        {/* <RouterTransition /> */}
        {children}
        <TrackerFooter />
      </AppShell.Main>
    </AppShell>
  );
}
