import { Tooltip } from "@mantine/core";

export const tooltipComponentSettings = Tooltip.extend({
  defaultProps: {
    color: "gray.7",
  },
  styles: {
    tooltip: {
      padding: 8,
    },
  },
});
