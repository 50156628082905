import { AlertProps, MantineTheme } from "@mantine/core";

export const alertComponentSettings: MantineTheme["components"]["Alert"] = {
  defaultProps: {
    underline: true,
    color: "teal.6",
  },
  styles: (theme: MantineTheme, props: AlertProps) => ({
    root: {
      padding: theme.spacing.lg,
      borderRadius: theme.radius.lg,
      border: `1px solid ${theme.colors[props.color || "gray"]?.[3]}`,
    },
  }),
};
