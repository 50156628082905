import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AppShell,
  Box,
  Button,
  Drawer,
  DrawerProps,
  Group,
  GroupProps,
  Menu,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  ArrowRight,
  Coin,
  Gear,
  List,
  MapPin,
  Repeat,
  SignOut,
  SlidersHorizontal,
  User,
  Users,
} from "@phosphor-icons/react";
import Logo from "app/ui/assets/logo/logo-wide-white.svg";
import {
  PrimaryTopNav,
  primaryTopNavLogoHeight,
  primaryTopNavParentGroupHeight,
  FifteenFiveImage,
} from "app/ui/components/primary-top-nav";
import { mantineComponentFactory } from "app/ui/lib/mantine-component-factory";
import { querySelectorAllAsync } from "app/ui/utils/querySelectorAllAsync";
import _ from "lodash";
import Image from "next/image";
import { useRouter } from "next/router";

import { useAuthGateDisclosure } from "hooks/useQueryParamDisclosure";
import { constrainImageDimensions } from "ui/utils/constrainImageDimensions";

import { useAuth } from "./AuthProvider";
import { HenCalendlySchedulerButton } from "./HenCalendlySchedulerButton";
import { PaidFeatureModal, usePaidFeatureModalDisclosure } from "./PaidFeatureModal";

const TRACKER_MAIN_NAV_ID = "tracker-header-main-nav";

function TrackerMainNavCtaGroup(groupProps: GroupProps) {
  const { user, pending } = useAuth();
  const [, { open }] = useAuthGateDisclosure();

  const router = useRouter();
  const isPublicBenchmarkingSite = router.pathname.startsWith("/benchmarking/s");

  if (pending) return null;
  return (
    <Group {...groupProps}>
      {user ? (
        <HeaderAvatarMenuButton />
      ) : (
        <>
          {isPublicBenchmarkingSite ? (
            <Button variant="filled" color="teal" sx={{ boxShadow: "none" }}>
              Login
            </Button>
          ) : (
            <Button
              variant="filled"
              color="teal"
              sx={{ boxShadow: "none" }}
              component="a"
              href="/login"
            >
              Login
            </Button>
          )}
          <HenCalendlySchedulerButton
            variant="filled"
            color="teal"
            bg="teal.6"
            sx={{ boxShadow: "none" }}
            rightSection={<ArrowRight size={16} weight="bold" />}
          >
            Schedule Demo
          </HenCalendlySchedulerButton>
        </>
      )}
    </Group>
  );
}

// @ts-expect-error TS7006
function getIsPayingCustomer(user) {
  return Boolean(user?.company);
}

export function useShowEmployeeDashboardUi(user: Record<string, unknown> | null) {
  // @ts-expect-error TS2339
  const employeeDashboardSettings = (user?.company?.employeeDashboardSettings || {}) as Record<
    string,
    unknown
  >;
  // @ts-expect-error TS2339
  return user && user.company?.enableEmployeeDashboard && employeeDashboardSettings?.enableAccess;
}

export function TrackerHeaderMainNav({ handleOpenDrawer }: { handleOpenDrawer: () => void }) {
  const { user, pending } = useAuth();

  // @ts-expect-error TS2339
  const isFifteenFivePartner = !!user?.company?.isFifteenFivePartner;

  const isPayingCustomer = getIsPayingCustomer(user);

  const userIsNonAdmin = user?.role !== "COMPANY_ADMIN";

  const [paidFeatureModalState, paidFeatureModalHandlers] = usePaidFeatureModalDisclosure();
  const showEmployeeDashboardUi = useShowEmployeeDashboardUi(user);

  const bgColor = !pending ? (isFifteenFivePartner ? "fifteenFive.1" : undefined) : "gray.6";

  return (
    <>
      <Box id={TRACKER_MAIN_NAV_ID}>
        <PrimaryTopNav.ParentGrid w="100%" {...(bgColor ? { bg: bgColor } : {})}>
          <PrimaryTopNav.SideSection>
            <Stack justify="center" h="100%">
              {!isFifteenFivePartner ? (
                <UnstyledButton
                  component="a"
                  href={isPayingCustomer ? "/" : "/benchmarking"}
                  display="inline-block"
                >
                  <Image
                    priority
                    src={Logo}
                    {...constrainImageDimensions({ ...Logo, newHeight: primaryTopNavLogoHeight })}
                    alt="Comprehensive"
                  />
                </UnstyledButton>
              ) : (
                <a href={isPayingCustomer ? "/" : "/benchmarking"}>
                  <Group wrap="nowrap" align="center" h="100%">
                    <FifteenFiveImage />
                    <Image
                      priority
                      src={Logo}
                      height={22}
                      width={90}
                      style={{ marginTop: 4 }}
                      alt="Comprehensive"
                    />
                  </Group>
                </a>
              )}
            </Stack>
          </PrimaryTopNav.SideSection>
          <PrimaryTopNav.MainSection>
            {pending ? null : (
              <PrimaryTopNav.LinkGroup
                justify="center"
                display={{ base: "none", lg: "flex" }}
                pos="relative"
                left={isFifteenFivePartner ? 100 : 0}
              >
                <PrimaryTopNav.LinkAtom
                  isBenchmarking
                  {...(isPayingCustomer
                    ? {
                        component: "a",
                        href: "/reviews",
                        target: "_top",
                      }
                    : {
                        onClick: () => paidFeatureModalHandlers.open("reviews"),
                      })}
                  leftSection={<Repeat size={16} fill="white" />}
                >
                  {isPayingCustomer ? "Reviews" : "Comp Reviews"}
                </PrimaryTopNav.LinkAtom>
                {isPayingCustomer ? (
                  <PrimaryTopNav.LinkAtom
                    isBenchmarking
                    // @ts-expect-error TS2322
                    component="a"
                    href="/company/employees"
                    leftSection={<Users size={16} fill="white" />}
                    target="_top"
                  >
                    Employees
                  </PrimaryTopNav.LinkAtom>
                ) : null}
                <PrimaryTopNav.LinkAtom
                  isBenchmarking
                  {...(isPayingCustomer
                    ? {
                        component: "a",
                        href: "/company/pay-ranges",
                        target: "_top",
                      }
                    : {
                        onClick: () => paidFeatureModalHandlers.open("pay-ranges"),
                      })}
                  leftSection={<SlidersHorizontal size={16} fill="white" />}
                >
                  Pay Ranges
                </PrimaryTopNav.LinkAtom>
                {isPayingCustomer ? (
                  <PrimaryTopNav.LinkAtom
                    isBenchmarking
                    // @ts-expect-error TS2322
                    component="a"
                    href={userIsNonAdmin ? "/benchmarking/s" : "/benchmarking-dashboard"}
                    leftSection={<MapPin size={16} fill="white" />}
                    target="_top"
                    isActive
                  >
                    Benchmarking
                  </PrimaryTopNav.LinkAtom>
                ) : null}
                {isPayingCustomer ? null : (
                  <PrimaryTopNav.LinkAtom
                    isBenchmarking
                    // @ts-expect-error TS2322
                    component="a"
                    href={"/benchmarking/s"}
                    leftSection={<MapPin size={16} fill="white" />}
                    target="_top"
                    isActive
                  >
                    Benchmarking
                  </PrimaryTopNav.LinkAtom>
                )}
                {isPayingCustomer ? (
                  showEmployeeDashboardUi ? (
                    <PrimaryTopNav.LinkAtom
                      isBenchmarking
                      // @ts-expect-error TS2322
                      component="a"
                      href="/personal/total-rewards"
                      target="_top"
                      leftSection={<Coin size={16} fill="white" />}
                    >
                      My Comp
                    </PrimaryTopNav.LinkAtom>
                  ) : null
                ) : (
                  <PrimaryTopNav.LinkAtom
                    isBenchmarking
                    // @ts-expect-error TS2322
                    onClick={() => paidFeatureModalHandlers.open("total-rewards")}
                    leftSection={<Coin size={16} fill="white" />}
                  >
                    Total Rewards
                  </PrimaryTopNav.LinkAtom>
                )}
              </PrimaryTopNav.LinkGroup>
            )}
          </PrimaryTopNav.MainSection>
          <PrimaryTopNav.SideSection span={1}>
            <TrackerMainNavCtaGroup
              gap={12}
              justify="flex-end"
              h="100%"
              display={{ base: "none", lg: "flex" }}
            />
            <Stack h="100%" justify="center" align="end" display={{ base: "flex", lg: "none" }}>
              <Box>
                <Button
                  variant="filled"
                  color="teal"
                  sx={{ boxShadow: "none" }}
                  size="compact-md"
                  px={5}
                  mr={-5}
                  onClick={handleOpenDrawer}
                >
                  <List fill="white" />
                </Button>
              </Box>
            </Stack>
          </PrimaryTopNav.SideSection>
        </PrimaryTopNav.ParentGrid>
      </Box>
      {isPayingCustomer ? null : (
        <PaidFeatureModal {...paidFeatureModalState} onClose={paidFeatureModalHandlers.close} />
      )}
    </>
  );
}

function removeParens(str: string) {
  return str.replace(/\([^()]*\)/g, "");
}

export function getInitials(name: string) {
  const tokens = removeParens(name).replace(/\s+/g, " ").trim().split(" ");
  const firstToken = _.first(tokens) || "";
  const lastToken = (tokens.length > 1 && _.last(tokens)) || "";
  const firstInit = firstToken[0];
  const lastInit = lastToken[0];
  return [firstInit, lastInit].join("").toUpperCase();
}

function HeaderAvatarMenuButton() {
  const { user } = useAuth();
  if (!user) throw Error("Need a user.");

  // @ts-expect-error TS2345
  const initials = useMemo(() => getInitials(user?.name || ""), [user?.name]);

  const logoutRedirectUrl = null;
  // const logoutRedirectUrl = useMemo(() => {
  //   if (typeof window === "undefined") return null;
  //   const { pathname } = window.location;
  //   if (pathname.startsWith("/benchmarking")) return pathname;
  //   return null;
  // }, []);

  return (
    <Menu position="bottom-end">
      <Menu.Target>
        <PrimaryTopNav.Avatar>{initials}</PrimaryTopNav.Avatar>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item sx={{ backgroundColor: "transparent !important", cursor: "auto" }}>
          {/*
           // @ts-expect-error TS2322 */}
          <Text>{user?.name}</Text>
          <Text fz="xs" sx={{ fontWeight: "normal" }} c="gray.6">
            {/*
             // @ts-expect-error TS2339 */}
            {user?.company?.name}
          </Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item leftSection={<User />} component="a" href="/account">
          My Account
        </Menu.Item>
        {user?.role === "COMPANY_ADMIN" ? (
          <Menu.Item leftSection={<Gear />} component="a" href="/settings/application/company">
            Company Settings
          </Menu.Item>
        ) : null}
        <Menu.Item
          leftSection={<SignOut />}
          component="a"
          href={`/logout${logoutRedirectUrl ? `?redirectUrl=${logoutRedirectUrl}` : ""}`}
        >
          Log Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const MobileDrawerButton = mantineComponentFactory.Button("MobileDrawerButton", {
  variant: "subtle",
  size: "lg",
});

function useDrawerOffsetTop(opened: boolean, selector: string) {
  const [offset, setOffset] = useState<number | null>(null);

  const handleSyncOffsetPosition = useCallback(async () => {
    const nodeList = await querySelectorAllAsync(selector);
    if (nodeList.length !== 1) {
      console.error("Couldn't determine offset.");
      setOffset(primaryTopNavParentGroupHeight); // a guess - better that than break the entire thing :shrug:
      return;
    }
    const navNode = nodeList[0];
    const { bottom } = navNode.getBoundingClientRect();
    setOffset(bottom);
  }, [setOffset]);

  useEffect(() => {
    if (!opened) {
      if (_.isNil(offset)) setOffset(null);
      return;
    }
    handleSyncOffsetPosition();
  }, [opened]);

  return offset;
}

function TrackerHeaderMobileDrawer(drawerProps: DrawerProps) {
  const { opened, ...drawerPropsRest } = drawerProps;
  const { user } = useAuth();
  const isPayingCustomer = getIsPayingCustomer(user);

  /*
   * We don't know how big this offset is in the event
   * that there's additional content on top of the main
   * header, so we compute dynamically.
   */
  const offset = useDrawerOffsetTop(opened, `#${TRACKER_MAIN_NAV_ID}`);

  const [paidFeatureModalState, paidFeatureModalHandlers] = usePaidFeatureModalDisclosure();
  return (
    <>
      <Drawer
        {...drawerPropsRest}
        opened={opened && !_.isNil(offset)}
        withOverlay={false}
        closeOnClickOutside
        styles={{
          content: {
            // TODO: This is hardcoded based on the height of the header. Should
            // be able to compute this.
            top: offset || 0,
            height: `calc(100vh - ${offset}px)`,
            transition: "transform 250ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s",
          },
        }}
        size="sm"
        position="top"
      >
        <Stack
          sx={{
            maxWidth: "100%",
            margin: "0 auto",
          }}
        >
          <MobileDrawerButton
            {...(isPayingCustomer
              ? {
                  component: "a",
                  href: "/reviews",
                  target: "_top",
                }
              : {
                  onClick: () => paidFeatureModalHandlers.open("reviews"),
                })}
            size="lg"
            leftSection={<Repeat size={16} />}
          >
            Reviews
          </MobileDrawerButton>
          {isPayingCustomer ? (
            <MobileDrawerButton size="lg" leftSection={<Users size={16} />}>
              Employees
            </MobileDrawerButton>
          ) : null}
          <MobileDrawerButton
            {...(isPayingCustomer
              ? {
                  component: "a",
                  href: "/company/pay-ranges",
                  target: "_top",
                }
              : {
                  onClick: () => paidFeatureModalHandlers.open("pay-ranges"),
                })}
            size="lg"
            leftSection={<SlidersHorizontal size={16} />}
          >
            Pay Ranges
          </MobileDrawerButton>
          <MobileDrawerButton
            leftSection={<MapPin size={16} />}
            onClick={drawerProps.onClose}
            size="lg"
          >
            Benchmarking
          </MobileDrawerButton>
          {isPayingCustomer ? null : (
            <MobileDrawerButton
              onClick={() => paidFeatureModalHandlers.open("total-rewards")}
              leftSection={<Coin size={16} />}
              size="lg"
            >
              Total Rewards
            </MobileDrawerButton>
          )}
        </Stack>
      </Drawer>
      {isPayingCustomer ? null : (
        <PaidFeatureModal {...paidFeatureModalState} onClose={paidFeatureModalHandlers.close} />
      )}
    </>
  );
}

export function TrackerHeader() {
  const [navDrawerOpened, navDrawerHandlers] = useDisclosure(false);

  return (
    <AppShell.Header h={primaryTopNavParentGroupHeight} withBorder={false}>
      {/* <MarketingHomeLinkBanner /> */}
      <TrackerHeaderMainNav handleOpenDrawer={navDrawerHandlers.open} />
      <TrackerHeaderMobileDrawer opened={navDrawerOpened} onClose={navDrawerHandlers.close} />
    </AppShell.Header>
  );
}
