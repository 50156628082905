import { forwardRef, Ref } from "react";

import { AnyObject } from "@hen/stdlib/types";
import {
  alpha,
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuItemProps,
  Skeleton,
  Stack,
} from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { ArrowElbowDownRight, CaretRight } from "@phosphor-icons/react";
import { mantineComponentFactory } from "app/ui/lib/mantine-component-factory";
import _ from "lodash";

export const primaryTopNavParentGroupHeight = 46;
export const primaryTopNavLogoHeight = 32;

const PrimaryTopNavParentGroup = mantineComponentFactory.Group("PrimaryTopNav.ParentGroup", {
  bg: "brand.7",
  px: 24,
  h: primaryTopNavParentGroupHeight,
});

const PrimaryTopNavSideSection = mantineComponentFactory.GridCol("PrimaryTopNav.SideSection", {
  mah: "100%",
  span: 1,
});

const PrimaryTopNavMainSection = mantineComponentFactory.GridCol("PrimaryTopNav.SideSection", {
  mah: "100%",
  span: 2,
});

const PrimaryTopNavParentGrid = mantineComponentFactory.Grid("PrimaryTopNav.ParentGrid", {
  columns: 4,
  bg: "brand.7",
  h: primaryTopNavParentGroupHeight,
  gutter: 0,
  px: 24,
  styles: {
    inner: {
      height: "100%",
    },
  },
});

export type PrimaryTopNavLinkAtomProps = ButtonProps & {
  isActive?: boolean;
  isBenchmarking?: boolean;
  overrideLinkColor?: string;
};

const PrimaryTopNavLinkAtom = forwardRef(function PrimaryTopNavLinkAtomRefTarget(
  {
    isActive,
    children,
    overrideLinkColor,
    isBenchmarking,
    ...buttonProps
  }: PrimaryTopNavLinkAtomProps,
  ref: Ref<HTMLButtonElement>,
) {
  return (
    <Button
      ref={ref}
      variant="subtle"
      px={8}
      styles={(theme) => {
        const linkColor: string = overrideLinkColor
          ? (_.get(theme.colors, overrideLinkColor) as unknown as string) ?? theme.colors.gray[1]
          : theme.colors.gray[1];

        const isClickable = Boolean(
          // It's not clear to me how to do this correctly here and this is so hyper-localized
          // that I doubt spending the time to get it right will provide much value in the short
          // term, so we cast. We'll definitely want to fix this in the future.
          (buttonProps as AnyObject).to ||
            (buttonProps as AnyObject).onClick ||
            (buttonProps as AnyObject).href,
        );
        return {
          root: {
            height: 26,

            // Required to support ellipsizing children bc text-overflow
            // doesn't work unless width is explicitly set *somewhere*.
            // @see https://css-tricks.com/flexbox-truncated-text/
            minWidth: 0,

            // Because overflow is hidden on the label, any vertical padding
            // results in the child elements being cut off, so we remove.
            paddingTop: 0,
            paddingBottom: 0,

            borderRadius: 200,
            cursor: isClickable ? "pointer" : "auto",
            ...(isActive
              ? {
                  backgroundColor: alpha(linkColor, 0.25),
                  "&:hover, &:active": {
                    backgroundColor: alpha(linkColor, 0.25),
                  },
                }
              : {
                  "&:hover": {
                    backgroundColor: isClickable ? alpha(linkColor, 0.2) : "transparent",
                  },
                  "&:active": {
                    backgroundColor: isClickable ? alpha(linkColor, 0.15) : "transparent",
                  },
                }),
          },
          section: {
            [`&[data-position="left"]`]: {
              marginRight: 6,
            },
          },
          inner: {
            minWidth: 0,
          },
          label: {
            color: linkColor,
            fontWeight: theme.other.fontWeights.medium,
            whiteSpace: "nowrap",
            overflow: isBenchmarking ? "ellipsis" : "hidden",
            textOverflow: "ellipsis",
          },
        };
      }}
      {...buttonProps}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          lineHeight: 1.25,
        }}
      >
        {children}
      </span>
    </Button>
  );
});

export function PrimaryTopNavBreadcrumbSpacerAtom({ color }: { color?: string }) {
  return (
    <Stack>
      <CaretRight color={color ?? "white"} />
    </Stack>
  );
}

export const PrimaryTopNavLinkGroup = mantineComponentFactory.Group(
  "PrimaryTopNav.BreadcrumbGroup",
  {
    gap: 4,
    h: "100%",

    // Need to disable wrap so that child elements can be effectively
    // ellipsized at the Atom level.
    wrap: "nowrap",
  },
);

export const PrimaryTopNavBreadcrumbGroup = PrimaryTopNavLinkGroup;

export const PrimaryTopNavBreadcrumbAtom = PrimaryTopNavLinkAtom;

interface ReviewBreadcrumbProps extends ButtonProps {
  terminal?: boolean;
  overrideLinkColor?: string;
}

/*
 * Combining generics with React.forwardRef isn't really an option,
 * so we assert.
 */
export const PrimaryTopNavBreadcrumb = forwardRef<HTMLButtonElement, ReviewBreadcrumbProps>(
  function ReviewBreadcrumb({ terminal, overrideLinkColor, ...buttonProps }, ref) {
    return (
      <>
        <PrimaryTopNavBreadcrumbAtom
          {...buttonProps}
          overrideLinkColor={overrideLinkColor}
          ref={ref}
        />
        {terminal ? null : <PrimaryTopNavBreadcrumbSpacerAtom color={overrideLinkColor} />}
      </>
    );
  },
) as <T = "button">(
  p: PolymorphicComponentProps<T, ButtonProps> & { terminal?: boolean; overrideLinkColor?: string },
) => JSX.Element;

export const PrimaryTopNavBreadcrumbMenu = mantineComponentFactory.Menu(
  "PrimaryTopNav.BreadcrumbMenu",
  {
    position: "bottom-start",
    styles: (theme) => ({
      itemLabel: {
        fontWeight: theme.other.fontWeights.medium,
      },
    }),
  },
);

function PrimaryTopNavBreadcrumbsPlaceholder() {
  return (
    <>
      <Skeleton h={24} w={120} sx={{ opacity: 0.5 }} />
      <PrimaryTopNav.BreadcrumbSpacerAtom />
      <Skeleton h={24} w={120} sx={{ opacity: 0.5 }} />
    </>
  );
}

function PrimaryTopNavBreadcrumbMenuItem<T = "button">({
  children,
  depth = 0,
  ...menuItemProps
}: PolymorphicComponentProps<T, MenuItemProps> & { depth?: number }) {
  return (
    <Menu.Item {...menuItemProps}>
      {depth > 0 ? (
        <Box pl={(depth - 1) * 20} display="inline-block" pr={8}>
          <ArrowElbowDownRight />
        </Box>
      ) : null}
      {children}
    </Menu.Item>
  );
}

const PrimaryTopNavAvatar = mantineComponentFactory.Avatar("PrimaryTopNav.Avatar", {
  size: 32,
  radius: 16,
  // @ts-expect-error TS2345
  component: "button",
  bg: "white",
  sx: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  },
});

export const PrimaryTopNav = {
  ParentGroup: PrimaryTopNavParentGroup,

  ParentGrid: PrimaryTopNavParentGrid,
  SideSection: PrimaryTopNavSideSection,
  MainSection: PrimaryTopNavMainSection,
  BreadcrumbGroup: PrimaryTopNavBreadcrumbGroup,
  BreadcrumbAtom: PrimaryTopNavBreadcrumbAtom,
  BreadcrumbSpacerAtom: PrimaryTopNavBreadcrumbSpacerAtom,
  Breadcrumb: PrimaryTopNavBreadcrumb,
  BreadcrumbMenu: PrimaryTopNavBreadcrumbMenu,
  BreadcrumbMenuItem: PrimaryTopNavBreadcrumbMenuItem,
  BreadcrumbsPlaceholder: PrimaryTopNavBreadcrumbsPlaceholder,
  LinkGroup: PrimaryTopNavLinkGroup,
  LinkAtom: PrimaryTopNavLinkAtom,
  Avatar: PrimaryTopNavAvatar,
};
