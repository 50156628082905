import _ from "lodash";
import { NextApiRequest } from "next";
import { NextParsedUrlQuery } from "next/dist/server/request-meta";
import { NextRouter } from "next/router";

import { YOE_OPTIONS } from "constants/explorer_selection_options";
import { descriptions } from "constants/levelData";
import { en } from "constants/locale/en";
import { DEFAULT_REGION_INPUTS, RegionInputs, Regions } from "constants/regions";
import {
  DEFAULT_SORT_KEY,
  ExploreQueryStringParams,
  QueryArgs,
  DEFAULT_SORT_DIR,
  ExplorerSortKey,
  ExplorerSortDir,
  TitleExplorerResult,
  CompanyExplorerResult,
  TitleExplorerCompanyRollup,
  CompanyExplorerTitleRollup,
} from "lib/frontend/benchmarking/types";
import { Decimal } from "prisma/benchmarking/client/runtime/binary";
import { StatsSummary } from "services/PostsService";
import { MAX_TITLE_ROLLUPS_TO_RENDER } from "utils/range_aggregators";

export function formatNum(value: number | Decimal, shouldRound: boolean): string {
  if (shouldRound) {
    return `$${Math.round((value as number) / 1000).toLocaleString("en-US")}k`;
  }
  return `$${Math.round(value as number).toLocaleString("en-US")}`;
}

export function formatRange(
  rangeLow?: number | Decimal | null,
  rangeHigh?: number | Decimal | null,
  opts?: { round: boolean },
): string {
  const shouldRound = opts?.round ?? true;

  if (_.isNil(rangeLow) && _.isNil(rangeHigh)) {
    return "N/A";
  }

  if (rangeLow === rangeHigh && !_.isNil(rangeLow)) {
    return formatNum(rangeLow, shouldRound);
  }

  const low = !_.isNil(rangeLow) ? formatNum(rangeLow, shouldRound) : "N/A";
  const high = !_.isNil(rangeHigh) ? formatNum(rangeHigh, shouldRound) : "N/A";

  return `${low} - ${high}`;
}

export function formatYearsExperience(low: number | null, high: number | null): string {
  if (_.isNil(low) && _.isNil(high)) {
    return "";
  } else if (_.isNil(high)) {
    return `${low}+ Years Experience`;
  } else {
    return `${low}-${high} Years Experience`;
  }
}

export function getOrdinal(n: number): string {
  let ord = "th";

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = "st";
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = "nd";
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = "rd";
  }

  return ord;
}

export function shouldIncludeClause(
  value: string | null | undefined | string[],
): value is string | string[] {
  if (_.isNil(value)) {
    return false;
  }

  if (typeof value === "string") {
    return value !== "";
  }

  return value.length > 0;
}

export function removeOffsetParam(queryString: string): string {
  const params = queryString.slice(1).split("&");
  const filteredParams = params.filter((param) => !param.startsWith("offset"));
  return "?" + filteredParams.join("&");
}

export function getMedian(numbers: number[]): number | null {
  if (numbers.length === 0) {
    return null;
  }

  numbers.sort((a, b) => a - b);

  if (numbers.length % 2 === 1) {
    return numbers[Math.floor(numbers.length / 2)];
  } else {
    const middleIndex = numbers.length / 2;
    return (numbers[middleIndex - 1] + numbers[middleIndex]) / 2;
  }
}

export function medianBy<T>(items: T[], keyOrFn: keyof T | ((item: T) => number)): number | null {
  if (items.length === 0) {
    return null;
  }

  let values: number[];

  if (typeof keyOrFn === "function") {
    values = _.map(items, keyOrFn);
  } else {
    values = _.map(items, (item) => item[keyOrFn] as number);
  }

  for (const value of values) {
    if (typeof value !== "number") {
      throw new Error(`PIE Err: Expected value to be a number, but got ${typeof value}`);
    }
  }

  return getMedian(values);
}

export function mapDictionary<T, U>(
  dictionary: { [key: string]: T },
  fn: (value: T, key: string) => U,
): { [key: string]: U } {
  return _.mapValues(dictionary, (value, key) => fn(value, key));
}

export function padString(string: string, length: number, padChar: string): string {
  return string.length >= length ? string : string + padChar.repeat(length - string.length);
}

export function dictionaryToSearchParams(dictionary: Partial<QueryArgs>): string {
  let searchParams = new URLSearchParams();

  /**
   * a helper for static type verification
   */
  function appendSearchParams(key: ExploreQueryStringParams, value: string) {
    searchParams.append(key, value);
  }

  const keys: ExploreQueryStringParams[] = [
    "companyName",
    "title",
    "jobFamily",
    "level",
    "subJobFamily",
    "location",
    "tier",
    "stage",
    "industry",
    "headcount",
    "yoe",
    "activeWithin",
    "inactiveSince",
  ];

  keys.forEach((key) => {
    const list = _.get(dictionary, key) as string[] | undefined;
    if (list) {
      if (key === "location") {
        if (_.isEqual(list.sort(), DEFAULT_REGION_INPUTS.sort())) {
          return;
        }
      } else if (key === "activeWithin") {
        return;
      } else if (key === "inactiveSince") {
        return;
      }

      list.sort().forEach((value) => appendSearchParams(key, value));
    }
  });

  const capitalRaised = dictionary.capitalRaised;
  if (capitalRaised) {
    appendSearchParams("capitalRaised", capitalRaised.join("-"));
  }

  const requireRanges = dictionary.requireRanges;
  if (requireRanges === true) {
    appendSearchParams("requireRanges", String(true));
  }

  const sortKey = dictionary.sortKey;
  if (sortKey && sortKey !== DEFAULT_SORT_KEY) {
    appendSearchParams("sortKey", sortKey);
  }

  const sortDir = dictionary.sortDir;
  if (sortDir && sortDir !== DEFAULT_SORT_DIR) {
    appendSearchParams("sortDir", sortDir);
  }

  const offset = dictionary.offset;
  if (!_.isNil(offset) && Number(offset) !== 0) {
    appendSearchParams("offset", String(offset));
  }

  const activeWithin = dictionary.activeWithin;
  if (activeWithin && activeWithin !== "12") {
    appendSearchParams("activeWithin", activeWithin);
  }

  const inactiveSince = dictionary.inactiveSince;
  if (inactiveSince) {
    appendSearchParams("inactiveSince", inactiveSince);
  }

  return searchParams.toString();
}

export const EMPTY_QUERY_OBJECT: QueryArgs = {
  title: [],
  jobFamily: [],
  level: [],
  subJobFamily: [],
  stage: [],
  industry: [],
  location: [],
  tier: [],
  companyName: [],
  headcount: [],
  yoe: [],
  capitalRaised: null,
  offset: null,
  sortKey: null,
  sortDir: null,
  requireRanges: true,
  activeWithin: null,
  inactiveSince: null,
};

// capRaised is of the form "1000000-2000000", yoe is of the form "1-5"
function parseQueryArgRange(capRaised: string | null): [number, number] | null {
  if (!capRaised) {
    return null;
  }

  const [low, high] = capRaised.split("-").map((s) => Number(s));

  if (Number.isNaN(low) || Number.isNaN(high)) {
    return null;
  }

  return [low, high];
}

export function searchParamsToDictionary(searchParams: URLSearchParams): QueryArgs {
  const capRaised = searchParams.get("capitalRaised");
  return {
    title: searchParams.getAll("title"),
    jobFamily: searchParams.getAll("jobFamily"),
    level: searchParams.getAll("level"),
    subJobFamily: searchParams.getAll("subJobFamily"),
    companyName: searchParams.getAll("companyName"),
    stage: searchParams.getAll("stage"),
    industry: searchParams.getAll("industry"),
    location: searchParams.getAll("location") as RegionInputs[],
    tier: searchParams.getAll("tier"),
    headcount: searchParams.getAll("headcount"),
    capitalRaised: parseQueryArgRange(capRaised),
    offset: searchParams.get("offset"),
    requireRanges: searchParams.get("requireRanges") === "true",
    yoe: searchParams.getAll("yoe") as YOE_OPTIONS[],
    sortKey: searchParams.get("sortKey") as ExplorerSortKey | null,
    sortDir: searchParams.get("sortDir") as ExplorerSortDir | null,
    activeWithin: searchParams.get("activeWithin"),
    inactiveSince: searchParams.get("inactiveSince"),
  };
}

export function isRegion(region: string): region is Regions {
  return Object.values(Regions).includes(region as Regions);
}

export function convertKeysToCamelCase(obj: object): object {
  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
      // @ts-ignore
      newObj[newKey] = typeof obj[key] === "object" ? convertKeysToCamelCase(obj[key]) : obj[key];
    }
  }
  return newObj;
}

export function getUrlFromNextRequest(req: NextApiRequest): URL | undefined {
  if (req.url) {
    return new URL(req.url, "http://dummyurl.com");
  } else {
    return undefined;
  }
}

/**
 * limits the number of titles included in each CompanyExplorerResult
 * to MAX_TITLE_ROLLUPS_TO_RENDER, some companies have too many titles and it makes the document
 * size too large in the accordion
 */
export function clipResults(results: CompanyExplorerResult[]): CompanyExplorerResult[] {
  return results.map((result) => {
    return {
      ...result,
      titles: _.slice(result.titles, 0, MAX_TITLE_ROLLUPS_TO_RENDER),
    };
  });
}

export function getQueryArgs(queryString: string): QueryArgs {
  const url = new URL(`https://comprehensive.io?${queryString}`);
  const searchParams = url.searchParams;
  return searchParamsToDictionary(searchParams);
}

export function hasPayPoint(
  result:
    | TitleExplorerResult
    | CompanyExplorerResult
    | TitleExplorerCompanyRollup
    | CompanyExplorerTitleRollup,
): boolean {
  return !!(result.salaryLow || result.salaryMid || result.salaryHigh);
}

export function getSalaryRangeOrPoint(
  result: TitleExplorerResult | CompanyExplorerResult,
  prefix?: string,
): string {
  const payPoints = [result.salaryLow ?? 0, result.salaryMid ?? 0, result.salaryHigh ?? 0].sort(
    (a, b) => (a > b ? 1 : -1),
  );

  let formatted = "";

  if (payPoints.length > 1) {
    formatted = formatRange(payPoints[0], payPoints[payPoints.length - 1], { round: true });
  } else {
    formatted = formatNum(payPoints[0], true);
  }

  if (prefix) {
    formatted = prefix + formatted;
  }

  return formatted;
}

export function getRangeOrPointFromPercentile(
  stats: StatsSummary,
  percentile: string = "50",
  prefix?: string,
): string {
  const percentiles = stats.percentiles[percentile as keyof StatsSummary["percentiles"]];

  let formatted = "";

  if (!percentiles.salaryLow || !percentiles.salaryHigh) return formatted;

  if (percentiles.salaryLow === percentiles.salaryHigh) {
    formatted = formatNum(percentiles.salaryHigh, true);
  } else {
    formatted = formatRange(percentiles.salaryLow, percentiles.salaryHigh, { round: true });
  }

  if (prefix) {
    formatted = prefix + formatted;
  }

  return formatted;
}

export function getTopResults(results: any[], prefix = "") {
  let topResults = prefix;
  const postCount = results.length < 3 ? results.length : 3;
  for (let i = 0; i < postCount; i++) {
    if (hasPayPoint(results[i])) {
      if (results[i].normalizedTitle) {
        topResults += ` ${results[i].normalizedTitle}:`;
      } else if (results[i].companyName) {
        topResults += ` ${results[i].companyName}:`;
      }
      topResults += ` ${getSalaryRangeOrPoint(results[i])}`;
      if (i !== postCount - 1) {
        topResults += ",";
      }
    }
  }

  return topResults;
}

export function getPageTitle(router: NextRouter, results: any[], stats: StatsSummary): string {
  let title = en.translations.seo_title;

  const routerQuery: NextParsedUrlQuery = router.query;
  const pathname = router.pathname;
  const queryString = routerQuery?.query?.[0] ?? "";
  const queryArgs = getQueryArgs(queryString);

  title = "Comprehensive.io | Free Salary Benchmarking Data";

  // if (pathname.includes("/t/") && routerQuery?.title) {
  //   const jobTitle = routerQuery.title;
  //   title = `${jobTitle} Salary`;
  //   // title only
  //   if (!queryArgs.companyName.length) {
  //     title += getRangeOrPointFromPercentile(stats, "50", " | ");
  //   }
  //   // title and company
  //   if (queryArgs.companyName.length === 1) {
  //     title = `${queryArgs.companyName[0]} ${title}`;
  //     if (results?.length && hasPayPoint(results[0])) {
  //       title += getSalaryRangeOrPoint(results[0], " | ");
  //     }
  //   }
  // }

  if (!title.includes("Comprehensive.io")) {
    title += " | Comprehensive.io";
  }

  return title;
}

export function getPageDescription(
  router: NextRouter,
  results: any[],
  stats: StatsSummary,
): string {
  // Set default description (will modify below if conditions permit)
  let description = en.translations.seo_description;
  const routerQuery: NextParsedUrlQuery = router.query;
  const pathname = router.pathname;
  const queryString = routerQuery?.query?.[0] ?? "";
  const queryArgs = getQueryArgs(queryString);
  const date = new Date();
  const monthYear = `${date.toLocaleString("default", { month: "long" })} ${date.getFullYear()}`;

  if (pathname.includes("/s/") && routerQuery?.title) {
    const jobTitle = routerQuery.title;
    // title only
    if (!queryArgs.companyName.length) {
      description = `Tracking salary ranges disclosed in ${jobTitle} job posts`;
      description += getRangeOrPointFromPercentile(stats, "50", ". Avg. ");
    }
    // title and company
    if (queryArgs.companyName.length === 1) {
      description = `Tracking salary ranges for the job title ${jobTitle} as disclosed by ${queryArgs.companyName[0]} in their job posts`;
      description += getRangeOrPointFromPercentile(stats, "50", ". Avg. ");
    }
  }

  if (description !== en.translations.seo_description) {
    description += ` (as of ${monthYear}).`;
  }

  return description;
}

export function getCanonicalUrl(router: NextRouter) {
  let canonicalUrl = `${process.env.NEXT_PUBLIC_APP_URL ?? "https://comprehensive.io"}`;
  let pathname = router.pathname;

  const dynamicSegmentPattern = /\/\[{1,2}(\.*)(\w*\s*\d*)]{1,2}/gi;
  if (pathname.search(dynamicSegmentPattern)) {
    pathname = pathname.replaceAll(dynamicSegmentPattern, "");
  }
  canonicalUrl += pathname;

  const routerQuery: NextParsedUrlQuery = router.query;

  if (routerQuery.title) {
    canonicalUrl += `/${routerQuery.title}`;
  }

  const queryArgs = getQueryArgs(routerQuery.query?.[0] as string);
  const skipKeys = ["offset", "requireRanges", "sortKey", "sortDir"];

  const argsArray: string[] = [];

  for (const item in queryArgs) {
    if (skipKeys.includes(item)) continue;

    const queryArg: string[] = _.get(queryArgs, item, []);

    if (!queryArg || !queryArg.length) continue;

    if (_.isArray(queryArg) && queryArg.length > 1) {
      // array of titles, companyNames, etc.
      const queryArgItemArray: string[] = [];

      queryArg.forEach((queryArgItem: string) => {
        queryArgItemArray.push(`${item}=${queryArgItem}`);
      });
      argsArray.push(queryArgItemArray.join("&"));
    } else {
      // single title, companyName, etc
      argsArray.push(`${item}=${queryArg[0]}`);
    }
  }

  if (argsArray.length) {
    const argsString = argsArray.length > 1 ? argsArray.join("&") : argsArray[0];
    canonicalUrl += `/${argsString}`;
  }

  return canonicalUrl;
}

export function getSeoFriendlyString(text: string) {
  return text
    .replace(/\s/g, en.translations.seo_separator)
    .replace(/-+/g, en.translations.seo_separator)
    .replace(/[^a-å0-9-]/gi, "")
    .toLowerCase();
}

// Use these Mercer levels and sort indices to sort the levels:
// Director (8)
// Manager (7)
// Team Leader (6)
// Entry/Developing Professional (3)
// Senior Professional (4)
// Export Professional (5)
// Entry Developing Support (1)
// Senior Support (2)
// Executive (9)

export function getMercerLevelSortKey(level: string): number {
  switch (level) {
    case "MD":
      return 8;
    case "MM":
      return 7;
    case "MT":
      return 6;
    case "PE":
      return 3;
    case "PS":
      return 4;
    case "PX":
      return 5;
    case "SE":
      return 1;
    case "SS":
      return 2;
    case "EX":
      return 9;
    default:
      const firstLevelPart = level.match(/[A-Z]+/);
      const secondLevelPart = level.match(/\d+/);

      if (firstLevelPart && secondLevelPart) {
        const firstPart = firstLevelPart[0];
        const secondPart = secondLevelPart[0];

        if (firstPart === "IC" || firstPart === "P") {
          return 100 + Number(secondPart);
        } else if (firstPart === "M") {
          return 200 + Number(secondPart);
        } else if (firstPart === "E") {
          return 300 + Number(secondPart);
        } else {
          return 0 + Number(secondPart);
        }
      } else {
        return 0;
      }
  }
}

export function getLevelSortKey(level: string): number {
  if (level === "Other") {
    return 100;
  }

  const match = level.match(/([A-Z]+)(\d+)/);
  if (!match) {
    return 0;
  }

  const [, type, number] = match;

  if (type === "IC") {
    return 1;
  } else if (type === "M") {
    return 2;
  } else if (type === "E") {
    return 3;
  } else {
    return 0;
  }
}

export function sortLevels(levels: string[]): string[] {
  // levels are: IC1, IC2 ... ICn, E1 ... En, M1 ... Mn, or Other
  // sort IC before M before E and then by number ascending
  // put "Other" at the end

  const sortedLevels = _.sortBy(levels, getLevelSortKey);

  return sortedLevels;
}

export function levelLabel(level: string): string {
  const levelDescription = descriptions[level as keyof typeof descriptions];
  if (levelDescription) {
    return `${level} - ${levelDescription.title}`;
  } else {
    return level;
  }
}

export const QUERY_ARGS_THAT_ARE_NOT_FILTERS = [
  "title",
  "jobFamily",
  "level",
  "offset",
  "sortKey",
  "sortDir",
  "activeWithin",
  "inactiveSince",
  "subJobFamily",
];

export function getSizeTrust(sampleSize: number): 0 | 1 | 2 | 3 | 4 {
  if (sampleSize <= 5) {
    return 0;
  } else if (sampleSize <= 25) {
    return 1;
  } else if (sampleSize <= 50) {
    return 2;
  } else if (sampleSize <= 100) {
    return 3;
  } else {
    return 4;
  }
}
export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
export function isUuid(value: string): value is string {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    value,
  );
}
