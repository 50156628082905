import { createElement, forwardRef } from "react";

import { useMantineTheme } from "@mantine/core";
import { IconProps as PhosphorIconProps } from "@phosphor-icons/react";
import _ from "lodash";

export type MantinePhosphorProps = {
  icon: React.ForwardRefExoticComponent<PhosphorIconProps & React.RefAttributes<SVGSVGElement>>;
} & PhosphorIconProps;

// This is ripped from the ThemeIcon source. In the future we
// may wish to embed this in the theme but for now it's fine.
const sizes = {
  xs: 16,
  sm: 20,
  md: 26,
  lg: 32,
  xl: 40,
};

/*
 * Provides a wrapper around a Phosphor icon
 * so you can directly access the mantine theme.
 */
export const MantinePhosphor = forwardRef<SVGSVGElement, MantinePhosphorProps>(
  ({ icon, color: themeColor = "gray.6", size: themeSize, ...iconProps }, ref) => {
    const theme = useMantineTheme();
    const size = themeSize ? _.get(sizes, themeSize, themeSize) : themeSize;
    const color = _.get(theme.colors, themeColor, themeColor) as string;

    return createElement(icon, {
      ref,
      color,
      size,
      ...iconProps,
    });
  },
);
MantinePhosphor.displayName = "MantinePhosphor";

export function mantinePhosphorFactory(displayName: string, props: Partial<MantinePhosphorProps>) {
  const IntermediateComponent = forwardRef<SVGSVGElement, Partial<MantinePhosphorProps>>(
    (instanceProps, ref) => {
      // @ts-expect-error TS2322
      return <MantinePhosphor {...props} {...instanceProps} ref={ref} />;
    },
  );
  IntermediateComponent.displayName = displayName;
  return IntermediateComponent;
}
