import { Stack, Grid, Flex, Text, Anchor, Divider, Image, Box } from "@mantine/core";

import { MailChimpNewsletterSignup } from "./MailChimpNewsletterSignup";

export function TrackerFooter() {
  return (
    <>
      <Flex py={{ base: 90, sm: 200 }} justify="center" sx={{ backgroundColor: "#26524c" }}>
        <Grid gutter={48} justify="center" grow w="100%" px={24} sx={{ maxWidth: 1600 }}>
          <Grid.Col
            span={{
              md: 6,
              lg: 6,
            }}
          >
            <Stack gap={24}>
              <Stack
                gap={12}
                p={24}
                sx={{
                  border: "1px solid rgba(255, 255, 255, 0.16)",
                  borderRadius: "8px",
                }}
              >
                <Box h={36} w="auto">
                  <Image
                    src="https://uploads-ssl.webflow.com/6334985cf0345a2dfa12a825/6335c5166a4301852950b80d_Logo-white-min.png"
                    h={"36px"}
                    w="auto"
                    alt="Comprehensive"
                  />
                </Box>
                <Text size="md" c="white">
                  © 2023 Comprehensive. All rights reserved.
                </Text>
                <Divider my={16} sx={{ opacity: "16%" }} />
                <Stack gap={4}>
                  <Text size="md" fw={500} sx={{ color: "white" }}>
                    Subscribe to our newsletter
                  </Text>
                  <Text size="sm" sx={{ color: "white" }}>
                    Get the latest updates on compensation trends, new products, and more.
                  </Text>
                </Stack>
                <MailChimpNewsletterSignup border />
              </Stack>
            </Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              md: 6,
              lg: 2,
            }}
          >
            <Stack gap={16}>
              <Text
                tt="uppercase"
                size="sm"
                sx={{ letterSpacing: 6, color: "rgba(255, 255, 255, 0.7)" }}
              >
                Product
              </Text>
              <Anchor
                component="a"
                href="https://www.comprehensive.io/"
                size="lg"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.85)",
                  "&:hover": { color: "white", transition: "all 200ms ease-in-out" },
                }}
              >
                Compensation Reviews
              </Anchor>
              <Anchor
                component="a"
                href="/benchmarking"
                size="lg"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.85)",
                  "&:hover": { color: "white", transition: "all 200ms ease-in-out" },
                }}
              >
                Benchmarking
              </Anchor>
            </Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              md: 6,
              lg: 2,
            }}
          >
            <Stack gap={16}>
              <Text
                tt="uppercase"
                size="sm"
                sx={{ letterSpacing: 6, color: "rgba(255, 255, 255, 0.7)" }}
              >
                Social
              </Text>
              <Anchor
                component="a"
                href="https://www.linkedin.com/company/comprehensiveio/"
                target="_blank"
                size="lg"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.85)",
                  "&:hover": { color: "white", transition: "all 200ms ease-in-out" },
                }}
              >
                Linkedin
              </Anchor>
              <Anchor
                component="a"
                href="https://twitter.com/comprehensiveio"
                target="_blank"
                size="lg"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.85)",
                  "&:hover": { color: "white", transition: "all 200ms ease-in-out" },
                }}
              >
                Twitter
              </Anchor>
            </Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              md: 6,
              lg: 2,
            }}
          >
            <Stack gap={16}>
              <Text
                tt="uppercase"
                size="sm"
                sx={{ letterSpacing: 6, color: "rgba(255, 255, 255, 0.7)" }}
              >
                Company
              </Text>
              <Anchor
                component="a"
                href="https://airtable.com/shr9y5tzUSBsrNhxR"
                target="_blank"
                size="lg"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.85)",
                  "&:hover": { color: "white", transition: "all 200ms ease-in-out" },
                }}
              >
                Contact Us
              </Anchor>
              <Anchor
                component="a"
                href="https://static.comprehensive.io/privacy.pdf"
                target="_blank"
                size="lg"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.85)",
                  "&:hover": { color: "white", transition: "all 200ms ease-in-out" },
                }}
              >
                Privacy Policy
              </Anchor>
              <Anchor
                component="a"
                href="https://static.comprehensive.io/cookie.pdf"
                target="_blank"
                size="lg"
                sx={{
                  textDecoration: "none",
                  color: "rgba(255, 255, 255, 0.85)",
                  "&:hover": { color: "white", transition: "all 200ms ease-in-out" },
                }}
              >
                Cookie Policy
              </Anchor>
            </Stack>
          </Grid.Col>
        </Grid>
      </Flex>
    </>
  );
}
