import { forwardRef } from "react";

import {
  Alert,
  AlertProps,
  Avatar,
  AvatarProps,
  Badge,
  BadgeProps,
  Box,
  BoxComponentProps,
  Button,
  ButtonProps,
  Card,
  CardProps,
  GridColProps,
  Container,
  ContainerProps,
  Grid,
  GridProps,
  Group,
  GroupProps,
  Menu,
  MenuProps,
  Paper,
  PaperProps,
  Portal,
  PortalProps,
  Radio,
  RadioProps,
  Skeleton,
  SkeletonProps,
  Stack,
  StackProps,
  Switch,
  SwitchProps,
  Tabs,
  TabsProps,
  Text,
  TextInput,
  TextInputProps,
  TextProps,
  Timeline,
  TimelineProps,
  Title,
  TitleProps,
  UnstyledButton,
  UnstyledButtonProps,
  NumberInput,
  NumberInputProps,
  RadioGroupProps,
  SelectProps,
  Select,
} from "@mantine/core";
import { DatePickerInput, DatePickerInputProps } from "@mantine/dates";
import { PolymorphicComponentProps } from "@mantine/utils";
import { packSx, useBoundPackSx } from "components/mantine-extended";

import { CardSectionProps, PropsWithSx } from "./props";

// For some reason Mantine doesn't include these in TComponentProps
// so we include them manually
type DefaultPropsExcludedFromMantine = {
  id?: string;
};

export function generateMantineComponentFactory<TMantineComponentProps extends PropsWithSx>(
  Component: React.FC<TMantineComponentProps>,
) {
  type TComponentProps = TMantineComponentProps & DefaultPropsExcludedFromMantine;
  function componentFactory(
    displayName: string,
    { sx: defaultSx = {}, ...defaultRest }: Partial<TComponentProps>,
  ) {
    const IntermediateComponent = forwardRef(
      ({ sx: instanceSx = {}, ...instanceRest }: TComponentProps, ref) => {
        const boundPackSx = useBoundPackSx();
        const mergedProps: TComponentProps = {
          sx: boundPackSx([defaultSx, instanceSx]),
          ...defaultRest,
          ...instanceRest,
        } as TComponentProps;
        return <Component {...mergedProps} ref={ref} />;
      },
    );
    IntermediateComponent.displayName = displayName;
    return IntermediateComponent;
  }
  return componentFactory;
}

/*
 * Special component factory that accounts for the common polymorphic
 * patterns with Button. We should make this more generic so we can employ
 * with other polymorphic components.
 *
 * Note also that at current, forwardRef doesn't work in here either. Unclear
 * as to whether or not we'll need it but if we do then we should figure that
 * out too. :sad:
 */
function buttonComponentFactory(
  displayName: string,
  { sx: defaultSx = {}, ...defaultRest }: Partial<ButtonProps>,
) {
  function IntermediateComponent<C = "button">({
    sx: instanceSx,
    ...instanceRest
  }: PolymorphicComponentProps<C, ButtonProps>) {
    // @ts-expect-error TS2352
    const mergedProps = {
      sx: packSx([defaultSx, instanceSx]),
      ...defaultRest,
      ...instanceRest,
      // Without this omission TS complains, throwing a type-misalignment
      // error with IntrinsicAttribures. Unclear why but we leave for now
      // as it probably doesn't have much in terms of adverse effects.
    } as Omit<PolymorphicComponentProps<C, ButtonProps>, "children">;
    return <Button {...mergedProps} />;
  }
  IntermediateComponent.displayName = displayName;
  return IntermediateComponent;
}

export const mantineComponentFactory = {
  Alert: generateMantineComponentFactory<AlertProps>(Alert),
  Avatar: generateMantineComponentFactory<AvatarProps>(Avatar),
  Badge: generateMantineComponentFactory<BadgeProps>(Badge),
  Box: generateMantineComponentFactory<BoxComponentProps>(Box),
  Button: buttonComponentFactory,
  Card: generateMantineComponentFactory<CardProps>(Card),
  CardSection: generateMantineComponentFactory<CardSectionProps>(Card.Section),
  Container: generateMantineComponentFactory<ContainerProps>(Container),
  Grid: generateMantineComponentFactory<GridProps>(Grid),
  GridCol: generateMantineComponentFactory<GridColProps>(Grid.Col),
  Group: generateMantineComponentFactory<GroupProps>(Group),
  Menu: generateMantineComponentFactory<MenuProps>(Menu),
  Paper: generateMantineComponentFactory<PaperProps>(Paper),
  // @ts-expect-error TS2559
  Portal: generateMantineComponentFactory<PortalProps>(Portal),
  Radio: generateMantineComponentFactory<RadioProps>(Radio),
  Skeleton: generateMantineComponentFactory<SkeletonProps>(Skeleton),
  Stack: generateMantineComponentFactory<StackProps>(Stack),
  Switch: generateMantineComponentFactory<SwitchProps>(Switch),
  Tabs: generateMantineComponentFactory<TabsProps>(Tabs),
  Text: generateMantineComponentFactory<TextProps>(Text),
  TextInput: generateMantineComponentFactory<TextInputProps>(TextInput),
  Timeline: generateMantineComponentFactory<TimelineProps>(Timeline),
  Title: generateMantineComponentFactory<TitleProps>(Title),
  UnstyledButton: generateMantineComponentFactory<UnstyledButtonProps>(UnstyledButton),
  NumberInput: generateMantineComponentFactory<NumberInputProps>(NumberInput),
  RadioGroup: generateMantineComponentFactory<RadioGroupProps>(Radio.Group),
  Select: generateMantineComponentFactory<SelectProps>(Select),
  DatePickerInput: generateMantineComponentFactory<DatePickerInputProps>(DatePickerInput),
};
